<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="留学机构">
      <table-page :config="storeConfig" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="国际学校">
      <table-page :config="config" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { reviewLabel, newCity, schoolNature, stayType, grade, curriculumSystems, country, countryAll } from '@/utils/public';
import TablePage from "@/components/TablePage";
export default {
  name: 'SaasAdminRebateRulesReviewDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      arr: [],
      activeKey: '1',
      storeConfig: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/institutionalShop/getList',
        filter: {
          initUrl: '/clientUser/institutionalShop/getAllInstitution',
          controls: [
            {
              key: 'organizationName',
              label: '机构名',
              type: 'text',
            },
            {
              key: 'cityId',
              label: '城市',
              type: 'souSelect',
              config: {
                options: 'city',
                textKey: 'city'
              }
            },
            {}
          ]
        },
        columns: [
          {
            key: 'organizationName',
            title: '机构名',
            width: '100px',
          },
          {
            key: 'city',
            title: '城市',
            width: '100px',
          },
          {
            key: 'score',
            title: '评分',
            width: '100px',
          },
          {
            key: 'benefitNumber',
            title: '预约福利',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/reservation",
              text: (val) => {
                return '预约福利 ' + val
              },
              query: 'organizationName'
            }
          },
          {
            key: 'serviceItems',
            title: '服务项目',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/serviceItems",
              text: (val) => {
                return '服务项目 ' + val
              },
              query: 'organizationName'
            }
          },
          {
            key: 'serviceTeam',
            title: '服务团队',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/serviceTeam",
              text: (val) => {
                return '服务团队 ' + val
              },
              query: 'organizationName'
            }
          },
          {
            key: 'honorsAndQualifications',
            title: '荣誉资质',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/honoraryQualifications",
              text: (val) => {
                return '荣誉资质 ' + val
              },
              query: 'organizationName'
            }
          },
          {
            key: 'caseNumber',
            title: '录取案例',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/admissionCases",
              text: (val) => {
                return '录取案例 ' + val
              },
              query: 'organizationName'
            },
          },
          {
            key: 'userEvaluation',
            title: '用户评价',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/userEvaluate",
              text: (val) => {
                return '用户评价 ' + val
              },
              query: 'organizationName'
            }
          },
          {
            key: 'articleNumber',
            title: '攻略资讯',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/strategyInfo",
              text: (val) => {
                return '攻略资讯 ' + val
              },
              query: 'organizationName'
            }
          },
          {
            key: 'locked',
            title: '门店状态',
            type: 'textCustom',
            width: '100px',
            config: {
              text: (val) => {
                return val == 0 ? '启用' : '停用'
              }
            }
          },
          {
            key: 'createdDate',
            title: '创建时间',
            type: 'datetime',
            width: '100px',
          },
          {
            key: 'lastModifiedDate',
            title: '最后修改时间',
            type: 'datetime',
            width: '100px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '100px' : '180px'
          }

        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        actions: [
          {
            key: 'edit',
            text: '新建机构门店',
            type: 'form',
            permission: '/clientUser/institutionalShop/add',
            config: {
              color: 'primary',
              title: '新建机构门店',
              initUrl: "/clientUser/institutionalShop/getValidInstitutionList",
              submitUrl: "/clientUser/institutionalShop/add",
              submitText: '保存',
              controls: [
                {
                  key: 'organizationId',
                  label: '机构名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'data'
                  }
                },
                {
                  key: 'handlingCountry',
                  label: '办理国家/地区',
                  type: 'text',
                  disabled: true,
                  getData: true
                },
                {
                  key: 'city',
                  label: '所在城市',
                  type: 'text',
                  disabled: true,
                  getData: true
                },
                {
                  key: 'score',
                  label: '评分',
                  type: 'rate',
                  allowHalf: true,
                  required: true,
                },
                {
                  key: 'label1',
                  label: '标签1',
                  type: 'text',
                },
                {
                  key: 'label2',
                  label: '标签2',
                  type: 'text',
                },
                {
                  key: 'label3',
                  label: '标签3',
                  type: 'text',
                },
                {
                  key: 'organizationProfile',
                  label: '机构简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 250, message: '请尽量精简内容，输入250字以内' }
                    ]
                  }
                },
                {
                  key: 'logo',
                  label: 'logo',
                  required: true,
                  tips: "建议上传正方形或圆形；大小不超过500kb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000
                  }
                },
                {
                  key: 'environmentPicture',
                  label: '环境图片',
                  required: true,
                  tips: "建议上传比例为 604:423 ；大小不超过1mb的图片；不超过9张",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 9,
                    maxSize: 9216000
                  }
                },
                {
                  key: 'popularOrNot',
                  label: '是否热门',
                  type: 'radio',
                  required: true,
                  config: {
                    initialValue: 0,
                    options: [
                      {
                        text: '否',
                        value: 0
                      },
                      {
                        text: '是',
                        value: 1
                      },
                    ]
                  }
                },
                {},
                {
                  key: 'commentContent',
                  label: '留学君点评',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 60, message: '请尽量精简内容，输入60字以内' }
                    ]
                  }
                },
                {
                  key: 'commentFlag',
                  label: '点评标签',
                  type: 'mSelect',
                  dealData: true,
                  config: {
                    options: reviewLabel
                  }
                },
                {}
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/institutionalShop/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/institutionalShop/getBasicInfoById",
              submitUrl: "/clientUser/institutionalShop/update",
              submitText: '保存',
              controls: [
                {
                  key: 'organizationId',
                  label: '机构名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'list'
                  }
                },
                {
                  key: 'handlingCountry',
                  label: '办理国家/地区',
                  type: 'text',
                  disabled: true,
                  getData: true
                },
                {
                  key: 'city',
                  label: '所在城市',
                  type: 'text',
                  disabled: true,
                  getData: true
                },
                {
                  key: 'score',
                  label: '评分',
                  type: 'rate',
                  allowHalf: true,
                  required: true,
                },
                {
                  key: 'label1',
                  label: '标签1',
                  type: 'text',
                },
                {
                  key: 'label2',
                  label: '标签2',
                  type: 'text',
                },
                {
                  key: 'label3',
                  label: '标签3',
                  type: 'text',
                },
                {
                  key: 'organizationProfile',
                  label: '机构简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 250, message: '请尽量精简内容，输入250字以内' }
                    ]
                  }
                },
                {
                  key: 'logo',
                  label: 'logo',
                  required: true,
                  tips: "建议上传正方形或圆形；大小不超过500kb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 512000
                  }
                },
                {
                  key: 'environmentPicture',
                  label: '环境图片',
                  required: true,
                  tips: "建议上传比例为 604:423 ；大小不超过1mb的图片；不超过9张",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 9,
                    maxSize: 9216000
                  }
                },
                {
                  key: 'popularOrNot',
                  label: '是否热门',
                  type: 'radio',
                  required: true,
                  config: {
                    initialValue: 0,
                    options: [
                      {
                        text: '否',
                        value: 0
                      },
                      {
                        text: '是',
                        value: 1
                      },
                    ]
                  }
                },
                {},
                {
                  key: 'commentContent',
                  label: '留学君点评',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 60, message: '请尽量精简内容，输入60字以内' }
                    ]
                  }
                },
                {
                  key: 'commentFlag',
                  label: '点评标签',
                  type: 'mSelect',
                  dealData: true,
                  config: {
                    options: reviewLabel
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '复制门店',
            type: 'form',
            repeatVerify: 'organizationId',
            verifyMsg: '该机构门店已存在',
            permission: '/clientUser/institutionalShop/copySave',
            config: {
              color: 'primary',
              title: '复制门店',
              initUrl: "/clientUser/institutionalShop/getBasicInfoById",
              submitUrl: "/clientUser/institutionalShop/copySave",
              submitText: '复制',
              controls: [
                {
                  key: 'organizationId',
                  label: '机构名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'list'
                  }
                },
                {
                  key: 'handlingCountry',
                  label: '办理国家/地区',
                  type: 'text',
                  disabled: true,
                  getData: true
                },
                {
                  key: 'city',
                  label: '所在城市',
                  type: 'text',
                  disabled: true,
                  getData: true
                },
                {
                  key: 'score',
                  label: '评分',
                  type: 'rate',
                  allowHalf: true,
                  required: true,
                },
                {
                  key: 'label1',
                  label: '标签1',
                  type: 'text',
                },
                {
                  key: 'label2',
                  label: '标签2',
                  type: 'text',
                },
                {
                  key: 'label3',
                  label: '标签3',
                  type: 'text',
                },
                {
                  key: 'organizationProfile',
                  label: '机构简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 250, message: '请尽量精简内容，输入250字以内' }
                    ]
                  }
                },
                {
                  key: 'logo',
                  label: 'logo',
                  required: true,
                  tips: "建议上传正方形或圆形；大小不超过500kb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000
                  }
                },
                {
                  key: 'environmentPicture',
                  label: '环境图片',
                  required: true,
                  tips: "建议上传比例为 604:423 ；大小不超过1mb的图片；不超过9张",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 9,
                    maxSize: 9216000
                  }
                },
                {
                  key: 'popularOrNot',
                  label: '是否热门',
                  type: 'radio',
                  required: true,
                  config: {
                    initialValue: 0,
                    options: [
                      {
                        text: '否',
                        value: 0
                      },
                      {
                        text: '是',
                        value: 1
                      },
                    ]
                  }
                },
                {}
              ]
            }
          },
        ]
      },
      config: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/internationalSchool/list',
        filter: {
          initUrl: '/clientUser/internationalSchool/getAllCity',
          controls: [
            {
              key: 'name',
              label: '学校名',
              type: 'text'
            },
            {
              key: 'cityId',
              label: '城市',
              type: 'souSelect',
              config: {
                options: 'data',
                textKey: 'city'
              }
            },
            {},
            {}
          ]
        },
        columns: [
          {
            key: 'name',
            title: '学校名',
            width: '100px',
          },
          {
            key: 'city',
            title: '城市',
            width: '100px',
          },
          {
            key: 'enrollmentProgramNum',
            title: '招生项目',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/enrollmentProgram",
              text: (val) => {
                return '招生项目 ' + val
              },
              query: 'name'
            }
          },
          {
            key: 'honorQualificationNum',
            title: '荣誉资质',
            width: '150px',
            type: 'link',
            config: {
              path: "/agency/honoraryQualificationsSchool",
              text: (val) => {
                return '荣誉资质 ' + val
              },
              query: 'name'
            }
          },
          {
            key: 'sort',
            title: '排序',
            width: '100px',
          },
          {
            key: 'locked',
            title: '门店状态',
            type: 'textCustom',
            width: '100px',
            config: {
              text: (val) => {
                return val == 0 ? '启用' : '停用'
              }
            }
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '60px' : '80px'
          }

        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        actions: [
          {
            key: 'edit',
            text: '新建国际学校门店',
            type: 'form',
            permission: '/clientUser/internationalSchool/add',
            config: {
              color: 'primary',
              title: '新建国际学校门店',
              initUrl: "/clientUser/internationalSchool/getValidSchoolList",
              submitUrl: "/clientUser/internationalSchool/add",
              submitText: '保存',
              controls: [
                {
                  key: 'logo',
                  label: 'logo',
                  required: true,
                  tips: "上传正方形或圆形，大小不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000
                  }
                },
                {},
                {
                  key: 'pictures',
                  label: '环境图片',
                  required: true,
                  tips: "上传比例为 xxx:xxx，大小不超过3mb的图片，最多9张",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 9,
                    maxSize: 1024 * 3000
                  }
                },
                {},
                {
                  key: 'institutionId',
                  label: '学校名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'data'
                  }
                },
                {
                  key: 'enName',
                  label: '英文名',
                  required: true,
                  type: 'text',
                },
                {
                  key: 'provinceCityDistrict',
                  label: '省市区',
                  type: 'text',
                  disabled: true,
                  getAddress: true
                },
                {
                  key: 'address',
                  label: '详细地址',
                  type: 'text',
                  disabled: true,
                  getAddress: true
                },
                {
                  key: 'property',
                  label: '办学性质',
                  type: 'select',
                  config: {
                    options: schoolNature
                  }
                },
                {
                  key: 'accommodation',
                  label: '住宿方式',
                  type: 'select',
                  config: {
                    options: stayType
                  }
                },
                {
                  key: 'studentNum',
                  label: '学生人数',
                  type: 'number',
                },
                {
                  key: 'teacherStudentRatio',
                  label: '师生比',
                  type: 'text',
                },
                {
                  key: 'gradeLevels',
                  label: '开设年级',
                  type: 'mSelect',
                  dealData: true,
                  config: {
                    options: grade
                  }
                },
                {
                  key: 'nationalityRequirements',
                  label: '国籍要求',
                  dealData: true,
                  type: 'mSelect',
                  config: {
                    options: countryAll
                  }
                },
                {
                  start: 'minTuitionFee',
                  end: 'maxTuitionFee',
                  label: '年学费参考 (人民币)',
                  unit: '元',
                  type: 'numberRange',
                },
                {
                  key: 'curriculumSystem',
                  label: '课程体系',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: curriculumSystems
                  }
                },
                {
                  key: 'studyAbroadDirection',
                  label: '留学方向',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: country
                  }
                },
                {
                  key: 'sort',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'introduction',
                  label: '学校简介',
                  type: 'textarea',
                  config: {}
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/internationalSchool/edit',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/internationalSchool/edit/get",
              submitUrl: "/clientUser/internationalSchool/edit",
              submitText: '保存',
              hiddenKey: {
                key: 'version',
              },

              controls: [
                {
                  key: 'logo',
                  label: 'logo',
                  required: true,
                  tips: "上传正方形或圆形，大小不超过1mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000
                  }
                },
                {},
                {
                  key: 'pictures',
                  label: '环境图片',
                  required: true,
                  tips: "上传比例为 xxx:xxx，大小不超过3mb的图片，最多9张",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 9,
                    maxSize: 1024 * 3000
                  }
                },
                {},
                {
                  key: 'institutionId',
                  label: '学校名',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'list'
                  }
                },
                {
                  key: 'enName',
                  label: '英文名',
                  required: true,
                  type: 'text',
                },
                {
                  key: 'provinceCityDistrict',
                  label: '省市区',
                  type: 'text',
                  disabled: true,
                  getAddress: true
                },
                {
                  key: 'address',
                  label: '详细地址',
                  type: 'text',
                  disabled: true,
                  getAddress: true
                },
                {
                  key: 'property',
                  label: '办学性质',
                  type: 'select',
                  config: {
                    options: schoolNature
                  }
                },
                {
                  key: 'accommodation',
                  label: '住宿方式',
                  type: 'select',
                  config: {
                    options: stayType
                  }
                },
                {
                  key: 'studentNum',
                  label: '学生人数',
                  type: 'number',
                },
                {
                  key: 'teacherStudentRatio',
                  label: '师生比',
                  type: 'text',
                },
                {
                  key: 'gradeLevels',
                  label: '开设年级',
                  type: 'mSelect',
                  dealData: true,
                  config: {
                    options: grade
                  }
                },
                {
                  key: 'nationalityRequirements',
                  label: '国籍要求',
                  dealData: true,
                  type: 'mSelect',
                  config: {
                    options: countryAll
                  }
                },
                {
                  start: 'minTuitionFee',
                  end: 'maxTuitionFee',
                  label: '年学费参考 (人民币)',
                  unit: '元',
                  type: 'numberRange',
                },
                {
                  key: 'curriculumSystem',
                  label: '课程体系',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: curriculumSystems
                  }
                },
                {
                  key: 'studyAbroadDirection',
                  label: '留学方向',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: country
                  }
                },
                {
                  key: 'sort',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'introduction',
                  label: '学校简介',
                  type: 'textarea',
                  config: {}
                },
                {},
                {
                  key: 'version',
                  label: '',
                },
              ]
            }
          }
        ]
      }
    };
  },
  mounted() {
  }

};
</script>

<style lang="less" scoped></style>